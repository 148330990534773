@import '../../lib/common';

.CareersPage {
  position: relative;
  color: #fff;
  z-index: 3;

  padding: 2rem 0 5rem;
  @include desktop {
    padding: 0 0 5rem;
  }
}

// ----------------------------------------------------------------------------

.CareersPage-culture {
  margin: auto;
  width: $mobileContentWidth;
  @include desktop {
    margin-top: 14rem;
    width: $desktopContentWidth;
  }
}

.CareersPage-cultureList {
  list-style: none;
  padding: 0;
  line-height: 1.65;
  letter-spacing: -0.03rem;

  font-size: 2rem;
  width: $mobileContentWidth;

  @include desktop {
    font-size: 2.3rem;
    width: $desktopContentWidth;

    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.CareersPage-cultureItem {
  margin-top: 4.6rem;
  @include desktop {
    margin: 0;
    width: 36.9rem;
  }
}

.CareersPage-cultureHeading {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: 800;
  line-height: 1;

  transform: translateX(-2rem);
  opacity: 0;
  transition: 0.5s opacity, 0.5s transform;

  font-size: 3rem;
  @include desktop {
    font-size: 5rem;
  }
}

.CareersPage-cultureItem-active .CareersPage-cultureHeading {
  opacity: 1;
  transform: rotate(0.72deg);
}

.CareersPage-underline {
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  border: 0;
  margin-bottom: 2rem;

  transform: translateY(1rem);
  opacity: 0;
  transition: 0.5s opacity, 0.5s transform;

  height: 1.4rem;
  @include desktop {
    height: 2.2rem;
  }
}

.CareersPage-cultureItem-active .CareersPage-underline {
  opacity: 1;
  transform: none;
}

.CareersPage-cultureItem:nth-child(1) .CareersPage-underline {
  background-image: url("./underline1.svg");
}

.CareersPage-cultureItem:nth-child(2) .CareersPage-underline {
  background-image: url("./underline2.svg");
}

.CareersPage-cultureItem:nth-child(3) .CareersPage-underline {
  background-image: url("./underline3.svg");
}

.CareersPage-culturePoint {
  opacity: 0;
  transform: translateY(2rem);
  transition: 0.5s opacity, 0.5s transform;
}

.CareersPage-cultureItem-active .CareersPage-culturePoint {
  opacity: 1;
  transform: none;
}

@include desktop {
  .CareersPage-cultureItem-active:nth-child(1) {
    .CareersPage-underline { transition-delay: 0.1s; }
    .CareersPage-culturePoint { transition-delay: 0.2s; }
  }
  .CareersPage-cultureItem-active:nth-child(2) {
    $timeOffset: 0.5s;
    .CareersPage-cultureHeading { transition-delay: $timeOffset; }
    .CareersPage-underline { transition-delay: $timeOffset + 0.1s; }
    .CareersPage-culturePoint { transition-delay: $timeOffset + 0.2s; }
  }
  .CareersPage-cultureItem-active:nth-child(3) {
    $timeOffset: 1s;
    .CareersPage-cultureHeading { transition-delay: $timeOffset; }
    .CareersPage-underline { transition-delay: $timeOffset + 0.1s; }
    .CareersPage-culturePoint { transition-delay: $timeOffset + 0.2s; }
  }
}

.CareersPage-principles {
  line-height: 1.65;

  margin-top: 4.6rem;
  width: $mobileContentWidth;
  font-size: 1.6rem;
  @include desktop {
    margin-top: 8rem;
    width: $desktopContentWidth;
    font-size: 1.8rem;
  }
}

// ----------------------------------------------------------------------------

.CareersPage-photos {
  margin: 6.8rem auto 0;
  width: $mobileContentWidth;

  @include desktop {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin: 6rem auto 0;
    width: $desktopContentWidth;
  }

  img {
    width: 100%;
    @include desktop {
      width: ($desktopContentWidth / 2) - 1rem;
    }
  }

  @include mobile {
    img+img {
      margin-top: 1rem;
    }
  }
}

// ----------------------------------------------------------------------------

.CareersPage-values {
  letter-spacing: -0.03rem;
  line-height: 1.65;

  margin: 5rem auto 0;
  width: $mobileContentWidth;
  @include desktop {
    margin: 5rem auto 0;
    width: $desktopContentWidth;
  }
}

.CareersPage-valuesLeft {
  opacity: 0;
  transform: translateY(2rem);
  transition: 0.5s transform, 0.5s opacity;

  font-size: 1.6rem;
  width: $mobileContentWidth;
  @include desktop {
    font-size: 1.8rem;
    width: 36.1rem;
  }
}

.CareersPage-valuesRight {
  opacity: 0;
  transform: translateY(2rem);
  transition: 0.5s transform, 0.5s opacity;

  margin-top: 5rem;
  font-size: 2rem;
  letter-spacing: -0.03rem;
  width: $mobileContentWidth;
  @include desktop {
    width: 65.3rem;
    font-size: 3.2rem;
    line-height: 1.4;
    margin-left: 48.6rem;
    margin-top: -4.3rem;
  }
}

.CareersPage-values-active {
  .CareersPage-valuesLeft {
    opacity: 1;
    transform: none;
  }
  .CareersPage-valuesRight {
    opacity: 1;
    transform: none;
    transition-delay: 0.35s;
  }
}

// ----------------------------------------------------------------------------

.CareersPage-vacanciesSlider {
  margin: 11rem auto 5rem;
  width: $mobileContentWidth;
  @include desktop {
    margin: 5rem auto;
    width: $desktopContentWidth;
  }
}

.CareersPage-vacanciesImage {
  transition: 0.333s ease-out transform, 0.333s ease-out opacity;
  transform: translateX(-12%);
  opacity: 0;

  width: 26rem;
  @include desktop {
    width: 76.1rem;
  }
}

.CareersPage-vacanciesImage-active {
  transform: none;
  opacity: 1;
}

// ----------------------------------------------------------------------------

.CareersPage-vacanciesBlock {
  width: $mobileContentWidth;
  @include desktop {
    display: flex;
    justify-content: space-between;
    width: $desktopContentWidth;
  }
  margin: auto;
}

.CareersPage-vacanciesList {
  list-style: none;
  padding: 0;
  line-height: 1.6;

  @include desktop {
    width: 55.5rem;
  }

  ul {
    list-style: disc
  }
  ul ul {
    list-style: circle
  }
}

.CareersPage-vacancy {
  position: relative;
  padding-bottom: 3.3rem;
  border-bottom: 2px solid rgba(#fff, 0.25);

  font-size: 1.6rem;
  margin-bottom: 3rem;
  @include desktop {
    font-size: 1.8rem;
    margin-bottom: 3.3rem;
    vertical-align: top;
  }

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.CareersPage-vacancyTitle {
  font-family: "Barlow Condensed";
  font-weight: 800;
  line-height: 1;
  font-size: 3.5rem;
  @include desktop {
    font-size: 3.8rem;
  }
}

.CareersPage-vacancySnippet {
  margin: 1.8rem 0 0;
}

.CareersPage-vacancyContent {
  height: 0;
  overflow: hidden;
  transition: height 0.5s;
  transition-delay: 0.5s;
  padding-bottom: 1.8rem;
}

.CareersPage-vacancyContentInner {
  opacity: 0;
  transition: 0.5s opacity;
  box-sizing: border-box;
  padding-top: 1.8rem;

  > *:first-child {
    margin-top: 0;
  }
  > *:last-child {
    margin-bottom: 0;
  }

  h2 {
    margin-bottom: 0;
  }

  > p > a {
    display: inline-block;
    padding: 1.8rem 0;
  }
}

.CareersPage-vacancyContent-visible {
  transition-delay: 0s;
  overflow: unset;

  .CareersPage-vacancyContentInner {
    opacity: 1;
    transition-delay: 0.5s;
  }
}

.CareersPage-vacancyCTA {
  font-weight: bold;
  text-decoration: underline;
  transition: 0.25s color;

  &:hover {
    color: #fcbd0c;
  }
}
