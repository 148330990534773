@import '../../lib/common';

.HomePage {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background: url(background-mobile.jpg) no-repeat bottom;
  background-size: cover;

  @include desktop {
    background-image: url(background-desktop.jpg);
  }
}

.HomePage-message {
  position: absolute;
  transition: opacity 0.25s, transform 0.25s;

  opacity: 0;
  transform: rotateZ(-2deg) translateY(2rem);
  left: 2rem;
  bottom: 2rem;
  @include desktop {
    left: 10%;
    top: 30%;
    bottom: unset;
  }
}

.App--withCookieNotice {
  @include mobile {
    .HomePage {
      bottom: 9rem;
    }
    .HomePage-message {
        bottom: 5.5rem;
    }
  }
}

.HomePage-message-show {
  transition-delay: 0.25s;
  opacity: 1;
  transform: rotateZ(-2deg);
}

.HomePage-arrow {
  margin-top: 1rem;
  transition: 0.25s transform;
  transform-origin: left;
}

.HomePage-messageLink:hover .HomePage-arrow {
  transform: scale(1.25);
}
