@import '../../lib/common';

.WorkPage {
  width: 100%;
}

// ----------------------------------------------------------------------------

.WorkPage-projects {
  list-style: none;
  padding: 8rem 0 4rem 0;
  margin: 0 auto;

  width: $mobilePageWidth;
  @include desktop {
    position: relative;
    left: 1rem;
    width: calc(#{$desktopContentWidth} + 2px);
  }
}

.WorkPage-projectsItem {
  display: inline-block;
  margin-bottom: 3rem;

  a {
    color: #000;
  }
}

// ----------------------------------------------------------------------------

.Project {
  position: relative;
  background: #999;

  margin: 2rem 2rem 4rem 0;
  width: $mobilePageWidth;
  height: 21rem;
  @include desktop {
    margin: 2rem 2rem 5rem 0;
    width: 55.6rem;
    height: 31.3rem;
  }
}

.Project-title {
  background: #fff;
  border-bottom: 0.6rem solid #999;
  position: absolute;
  bottom: 0px;
  transform: translateY(50%);
  box-sizing: border-box;

  font-family: "Barlow Condensed";
  font-weight: 800;
  line-height: 0.9;

  z-index: 2;

  font-size: 3rem;
  width: 33.5rem;
  left: 2rem;
  padding: 1.6rem 1.8rem;
  @include desktop {
    font-size: 3.8rem;
    width: 50.2rem;
    left: 2.7rem;
    padding: 2.5rem;
  }
}

.Project-imageBox {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.Project-image {
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  background-size: cover;
  background-repeat: no-repeat;
}

.Project-image-top {
  background-position: top;
}

.Project-image-middle {
  background-position: center;
}

.Project-image-bottom {
  background-position: bottom;
}

.Project:hover .Project-image {
  transform: scale(1.2) rotate(4deg);
}
