@import '../../lib/common';

.Footer {
  position: relative;
  z-index: 3;
  color: #000;
  line-height: 2;

  font-size: 1.4rem;
  padding: 5.5rem 0 4rem 2rem;
  @include desktop {
    font-size: 1.8rem;
    padding: 5rem 0 4rem;
  }

  a {
    color: #000;
    transition: 0.25s color;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      color: #d73a06;
    }
  }
}

.Footer--solid {
  background: #fff;
}

.Footer-container {

  a {
    font-size: 1.6rem;
    @include desktop {
      font-size: 1.8rem;
    }
  }

  @include desktop {
    display: flex;
    width: 114.2rem;
    margin: auto;
  }
}

.Footer-policies {
  margin-top: 1.6rem;

  @include desktop {
    width: 114.2rem;
    margin: 1.6rem auto;
    font-size: 1.6rem;

    br {
      display: none;
    }
  }
}

.Footer-contact {
  @include desktop {
    width: 30%;
  }
}

.Footer-contactLinks {
  list-style: none;
  padding: 0;

  margin: 0;
  margin-top: 1rem;
  li+li { margin-top: 1rem; }
  @include desktop {
    margin: 0;
    li+li { margin-top: 0; }
  }
}

.Footer-careers {
  margin-top: 3.5rem;
  div { margin-bottom: 1rem; }
  @include desktop {
    margin-top: 0;
    div { margin-bottom: 0; }
    width: 30%;
  }
}

.Footer-findUs {
  margin-top: 4rem;
  @include desktop {
    margin-top: 0;
    width: 30%;
  }
}

.Footer-address {
  list-style: none;
  padding: 0;
  margin: 0;
  font-weight: bold;

  font-size: 1.6rem;
  margin-top: 0.8rem;
  li+li { margin-top: 1rem }
  @include desktop {
    font-size: 1.8rem;
    margin-top: 0;
    li+li { margin-top: 0 }
  }
}

.Footer-social {
  display: block;
  margin-top: 3.6rem;

  @include desktop {
    margin: 0;
    width: 10%;
  }
}

.Footer-socialLinks {
  list-style: none;
  padding: 0;
  margin: 0;


  li {
    @include mobile {
      display: inline-block;
    }
  }

  li+li {
    margin-left: 2rem;

    @include desktop {
      margin: 0.5rem 0 0;
    }
  }

  img {
    transition: 0.25s filter;
  }
  a:hover img {
    filter: invert(26%) sepia(94%) saturate(1598%) hue-rotate(355deg) brightness(97%) contrast(104%);
  }
}
