$desktopPageWidth: 144rem;
$mobilePageWidth: 37.5rem;

$desktopContentWidth: 115.2rem;
$mobileContentWidth: 33.4rem;

@mixin desktop {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin mobile {
  @media screen and (max-width: 768px) {
    @content;
  }
}

@keyframes headerImageIn {
  from {
    opacity: 0;
    transform: translateX(75%) rotate(25deg);
  }

  to {
    opacity: 1;
    transform: unset;
  }
}

// ----------------------------------------------------------------------------

.Page {
  width: 100%;
}

// ----------------------------------------------------------------------------

.Hero {
  width: 100%;
  position: relative;
  min-height: 53rem;
  @include desktop {
    height: 100rem;
    min-height: 100vh;
  }
}

.Hero-background {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #E4DFE4 41.67%, #E4DFE4 50%, #F5F5F5 87.7%);
  z-index: 1;
}

.Hero-content {
  position: relative;
  min-height: 55rem;
  height: calc(100vh - 6rem);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 7rem 0 2rem;
  box-sizing: border-box;
  z-index: 3;

  @include desktop {
    padding: 0;
    justify-content: center;
    margin: auto;
    width: $desktopPageWidth;
    height: 100vh;
  }
}

.Hero-title {
  z-index: 3;
  margin-left: 2rem;
  @include desktop {
    margin-left: ($desktopPageWidth - $desktopContentWidth) / 2;
  }

  transition: 0.5s opacity, 0.5s transform;
  @include mobile {
    transition-delay: 0.25s;
  }
}

.Hero-title-out {
  transform: translateY(-4rem);
  opacity: 0;
}

.Hero-image {
  display: block;
  animation-fill-mode: backwards;
  animation-delay: 0.25s;
  animation-duration: 0.75s;
  animation-name: headerImageIn;
  margin: auto;
  @include desktop {
    position: absolute;
    top: 0;
    right: 0;
    height: 100rem;
    min-height: 100vh;
    display: flex;
    align-items: center;
  }
  transition: 0.5s opacity, 0.5s transform;
}

.Hero-image-out {
  transform: translateY(-4rem);
  opacity: 0;
}

.Hero-img-about {
  max-width: $mobileContentWidth;
  max-height: calc(100vh - 35rem);
  @include desktop {
    max-width: 82rem;
    max-height: unset;
  }
}

.Hero-img-careers {
  transform: rotate(38.3deg) scale(1.4);
  max-width: $mobileContentWidth;
  max-height: calc(100vh - 35rem);
  @include desktop {
    transform: translateY(5%) translateX(-20%) rotate(38.3deg);
    max-width: 80rem;
    max-height: unset;
  }
}

.Hero-scroll {
  display: none;
  @include desktop {
    display: block;
    position: absolute;
    bottom: 3rem;
    left: ($desktopPageWidth - $desktopContentWidth) / 2;
    width: 5rem;
    transition: 0.5s opacity, 0.5s transform
  }
}

.Hero-scroll-out {
  transform: translateY(-4rem);
  opacity: 0;
}
