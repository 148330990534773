.FadeContainer {
  opacity: 0;
  transform: translateY(1em);
  transition: opacity 0.5s, transform 0.5s;
}

.FadeContainer--visible {
  opacity: 1;
  transform: translateY(0);
}
