@import '../../lib/common';

.NewsPage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.NewsPage--out {
  position: fixed;
}

// ----------------------------------------------------------------------------

.NewsPage-content {
  position: relative;

  margin: 8rem auto 4rem;
  width: $mobileContentWidth;
  @include desktop {
    margin: 16rem auto 4rem;
    width: $desktopContentWidth;
  }
}

// ----------------------------------------------------------------------------

.NewsPage-listing {
  list-style: none;
  padding: 0;
}

.NewsPage--out .NewsPage-listing {
  opacity: 0;
  transition: opacity 0.75s;
  pointer-events: none;
}

.NewsItem {
  display: block;
  padding: 3.5rem 0;
  border-bottom: 1px solid #000;
  position: relative;
  cursor: pointer;

  @include desktop {
    display: flex;
    flex-direction: column;
    min-height: 18.75rem;
  }
}

.NewsItem-title {
  font-family: "Barlow Condensed";
  font-weight: 800;
  font-size: 3rem;
  line-height: 0.9;

  @include desktop {
    width: 82.6rem;
  }
}

.NewsItem-description {
  margin: 2rem 0;

  font-size: 1.4rem;
  line-height: 1.35;

  @include desktop {
    font-size: 1.6rem;
    line-height: 1.65;
    margin: 2rem 0;
    width: 55.5rem;
    flex-grow: 1;
  }
}

.NewsItem-cta {
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.6rem;
  line-height: 1.65;
  transition: 0.75s color;

  &:hover {
    color: #d73a06;
  }
}

.NewsItem-image {
  position: absolute;
  right: 0;
  top: 3.5rem;
  width: 25rem;

  display: none;
  @include desktop {
    display: block;
  }
}

.NewsPage-transitionTarget {
  position: fixed;
  left: 0;
  width: $desktopContentWidth;
  padding: 3.6rem 0;
}

.NewsPage--out .NewsPage-transitionTarget {
  transition: top 0.75s;
  transition-delay: 0.5s;
}

.NewsPage-transitionTarget .NewsItem-title {
  transform: translateY(-0.1rem);
}

.NewsPage--out .NewsPage-transitionTarget .NewsItem-title {
  font-size: 10rem;
  line-height: 0.85;
  width: 60rem;
  letter-spacing: -0.2rem;
  transform: translateY(4.2rem) rotate(-2deg);
  transition: font-size 0.75s, letter-spacing 0.75s, line-height 0.75s, width 0.75s, transform 0.75s;
  transition-delay: 0.5s;
}

.NewsPage--out .NewsPage-transitionTarget .NewsItem-image {
  width: 52rem;
  transition: width 0.75s;
  transition-delay: 0.5s;
}

.NewsPage--out .Footer {
  opacity: 0;
  transition: opacity 0.5s;
}
