@import '../../lib/common';

.AboutPage {
  position: relative;
  color: #fff;
  z-index: 3;
}

// ----------------------------------------------------------------------------

.AboutPage-block {
  position: relative;
  margin: auto;
  width: $mobileContentWidth;
  @include desktop {
    width: $desktopContentWidth;
  }
}

// ----------------------------------------------------------------------------

.AboutPage-background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  transition: 1s opacity;
  background: #090150;
  z-index: 2;
}

// ----------------------------------------------------------------------------

.AboutPage-statement {
  padding: 0 0 8rem;
  font-size: 1.8rem;
  line-height: 1.65;

  @include desktop {
    padding: 0 0 12rem;
    font-size: 2.3rem;
    width: 60%;
  }
}

// ----------------------------------------------------------------------------

.AboutPage-establishedBlock {
  position: relative;
  @include desktop {
    display: flex;
    align-items: center;
    margin: auto;
    width: $desktopContentWidth;
  }
}

.AboutPage-establishedImage {
  width: 100%;
}

.AboutPage-establishedSlider {
  display: block;
  transition: 0.333s ease-out transform, 0.333s ease-out opacity;
  opacity: 0;

  margin: 6rem auto 0;
  transform: translateX(12%);
  width: $mobileContentWidth;
  @include desktop {
    margin: 0;
    transform: translateX(-38%);
    width: $desktopContentWidth + 20rem;

    position: absolute;
    left: 50%;
    z-index: 2;
  }
}

.AboutPage-establishedSlider-active {
  opacity: 1;

  transform: none;
  @include desktop {
    transform: translateX(-50%);
  }
}

// ----------------------------------------------------------------------------

.AboutPage-goodAt {
  padding: 5.5rem 0;
  font-size: 1.8rem;
  line-height: 1.65;
  @include desktop {
    padding: 12rem 0;
    font-size: 2.3rem;
  }
}

.AboutPage-goodAtList {
  list-style: none;
  padding: 0;

  li {
    margin: 1.5rem 0;
    opacity: 0;
    transform: translateY(2rem);
    transition: transform 0.5s, opacity 0.5s;
  }

  @include desktop {
    margin-top: 4rem;
    li {
      margin: 0.5rem 0;
    }
    li:nth-child(2) {
      margin-left: 2em;
    }
    li:nth-child(3) {
      margin-left: 4em;
    }
  }
}

.AboutPage-goodAtList-active {
  li {
    opacity: 1;
    transform: none;
  }
  li:nth-child(2) {
    transition-delay: 0.5s;
  }
  li:nth-child(3) {
    transition-delay: 1s;
  }
}

.AboutPage-blockText {
  transform: rotate(0.5deg);
  .BlockText-main {
    color: #D73A06;
    line-height: 0.9;
    padding: 0 0.1em;
    letter-spacing: -0.1rem;
    font-size: 4.2rem;
    margin: 0;
    @include desktop {
      padding: 0.01em 0.1em 0.03em;
      font-size: 7.8rem;
    }
  }
  .BlockText-offset {
    top: -0.2rem;
  }
}

.AboutPage-blockText-inverse {
  .BlockText-main {
    background: #D73A06;
    color: #fff;
  }
}

// ----------------------------------------------------------------------------

.AboutPage-designBlock {
  width: 100%;
  text-align: right;
  margin-top: -1.7rem;
  @include desktop {
    margin-top: -5rem;
  }
}

.AboutPage-designSliderLimiter {
  position: relative;
  @include desktop {
    width: $desktopContentWidth;
    margin: auto;
  }
}

.AboutPage-designImage {
  position: relative;
  top: 1rem;
  width: 21rem;
  @include desktop {
    top: 0rem;
    left: 11rem;
    width: 51rem;
  }
}

.AboutPage-designSlider {
  position: absolute;
  transition: 0.333s ease-out transform, 0.333s ease-out opacity;
  transform: translateX(-12%);
  opacity: 0;

  top: 18rem;
  left: 1.25rem;
  width: 35rem;
  @include desktop {
    top: 15rem;
    left: 0;
    width: 103rem;
  }
}

.AboutPage-designSlider-active {
  transform: none;
  opacity: 1;
}

.AboutPage-designStatement {
  line-height: 1.65;

  font-size: 1.8rem;
  margin-top: 20rem;
  @include desktop {
    font-size: 2.3rem;
    letter-spacing: -0.03rem;
    margin-top: 24rem;
    width: 57rem;
    padding: 0;
    position: relative;
  }
}

.AboutPage-fluxx {
  padding: 2rem 0 5rem;
  font-size: 1.6rem;
  line-height: 1.65;

  @include desktop {
    padding: 0 0 10rem;
    margin-left: auto;
    margin-top: -5.5rem;
    width: 46.6rem;
    font-size: 1.8rem;
    line-height: 3rem;
    letter-spacing: -0.03rem;
  }

  a {
    color: #fff;
    text-decoration: underline;
    font-weight: 700;
  }
}

// ----------------------------------------------------------------------------

.AboutPage-workLinkSection {
  position: relative;
  background: #D73A06;
  box-sizing: border-box;
  margin-bottom: -1px;
  overflow: hidden;

  padding-top: 4.5rem;
  padding-left: 2.1rem;
  height: 17.6rem;
  @include desktop {
    padding: 0;
    height: 28.8rem;
  }
}

.AboutPage-workLinkImage {
  position: absolute;
  top: 50%;
  transform: translateY(-59.5%);
  right: -6rem;

  width: 24rem;
  @include desktop {
    width: 55.5rem;
  }
}

.AboutPage-workLink {
  @include desktop {
    height: 100%;
    width: 90%;
    max-width: $desktopContentWidth;
    margin: auto;
    display: flex;
    align-items: center;
  }
}

.AboutPage-workLinkText {
  transform: rotate(-1.1deg);
  .BlockText-main {
    color: #090150;
    line-height: 0.9;
    padding: 0 0.1em;

    font-size: 3rem;
    letter-spacing: 0;
    margin: 0;
    @include desktop {
      font-size: 5rem;
      letter-spacing: -0.1rem;
    }
  }
  .BlockText-offset {
    top: -0.2rem;
  }
}

.AboutPage-workLinkArrow {
  width: 4rem;
  margin-top: 1rem;
  transition: 0.25s transform;
  transform-origin: left;

  @include desktop {
    width: 10rem;
    margin-top: 0;
    margin-left: 1em;
  }
}

.AboutPage-workLinkSection:hover .AboutPage-workLinkArrow {
  transform: translateX(-0.2rem) scale(1.2);
}
