@import url('https://fonts.googleapis.com/css2?family=Barlow+Condensed:wght@800&family=Libre+Baskerville:wght@400;700&display=swap');

@import './normalize.css';
@import './lib/common';

@font-face {
    font-family: 'PremiumUtra31';
    src: url('./fonts/PremiumUtra31.woff2') format('woff2'),
         url('./fonts/PremiumUtra31.woff') format('woff'),
         url('./fonts/PremiumUtra31.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

html {
  font-size: 62.5%;
  overflow-y: scroll;
}

.showingMenu {
  overflow: hidden;
}

body {
  margin: 0;
  font-family: 'Libre Baskerville', serif;
  background: linear-gradient(180deg, #E4DFE4 41.67%, #E4DFE4 50%, #F5F5F5 87.7%);
  background-attachment: fixed;
  font-size: 1.8rem;
  overflow: hidden;
}

a {
  color: #000;
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

button {
  background: none;
  padding: 0;
  margin: 0;
  border: none;
  color: inherit;
  text-align: inherit;
  outline: none;
  cursor: pointer;
}

h2 {
  font-size: 200%;
  font-weight: normal;
  font-family: "PremiumUtra31";
  line-height: 1;
  margin-top: 1em;

  @include desktop {
    font-weight: bold;
  }
}

.Container {
  position: fixed;
  top: 0;
}

.TempSectionTitle {
  text-align: center;
  margin-top: 10rem;
}

.ScribbleText {
  line-height: 1;
  font-family: "PremiumUtra31";
  display: block;
  z-index: 2;
  position: relative;
  top: 0.8rem;
  white-space: nowrap;

  font-size: 3.6rem;
  margin: 0 0 0.6rem 0.2rem;
  @include desktop {
    margin: 0.2rem 0 0.6rem 0.1rem;
    font-weight: 800;
    font-size: 4rem;
  }
}

.CookieNotice {
  background: #090150;
  z-index: 10000;
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  width: 100%;
  color: #fff;

  a {
    color: #fff;
    text-decoration: underline;
  }
}

.CookieNotice-wrapper {
  display: flex;
  align-items: center;
  margin: auto;
  padding: 1rem 0;
  justify-content: space-between;

  @include desktop {
    width: $desktopContentWidth;
    br {
      display: none;
    }
  }

  @include mobile {
    width: $mobileContentWidth;
    flex-direction: column;
    br {
      margin-top: 2rem;
    }
  }
}

.CookieNotice-text {
  font-size: 1.4rem;
  line-height: 1.45;
}

.CookieNotice-buttons {
  list-style: none;
  display: flex;
  font-size: 1.4rem;
  padding: 0;

  li+li {
    margin-left: 1rem;
  }
}

.CookieNotice-button {
  background: rgba(#fff, 0.25);
  border-radius: 0.5rem;
  padding: 1rem;
}
