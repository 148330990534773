@import '../../lib/common';

.NewsArticlePage {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

// ----------------------------------------------------------------------------

.NewsArticlePageContent {
  position: relative;
  border-bottom: 1px solid black;

  width: $mobileContentWidth;
  padding-bottom: 4rem;
  margin: 12rem auto 0;
  @include desktop {
    width: $desktopContentWidth;
    padding-bottom: 8rem;
    margin: 19.7rem auto 0;
  }

  h2 {
    margin-top: 1.5em;
    margin-bottom: 0;
  }

  hr {
    border: none;
    border-bottom: 1px solid black;
    margin: 4rem 0;
  }
}

.NewsArticlePage-date {
  font-family: "PremiumUtra31";
  transform: rotate(-2deg);
  margin: 0 auto -0.5rem auto;

  width: $mobileContentWidth;
  font-size: 3.6rem;
  @include desktop {
    width: unset;
    font-weight: 800;
    font-size: 4rem;
    opacity: 0;
  }
}

.NewsArticlePage-title {
  font-family: "Barlow Condensed";
  line-height: 0.85;
  letter-spacing: -0.2rem;
  transform: rotate(-2deg);

  margin: 0 auto 2rem;
  width: $mobileContentWidth;
  font-size: 6rem;
  @include desktop {
    margin: 0;
    width: 60rem;
    font-size: 10rem;
  }
}

.NewsArticlePage--fadeIn {
  .NewsArticlePage-date {
    opacity: 1;
    transition: 0.5s opacity;
  }
  .NewsArticlePage-text {
    opacity: 1;
    transition: 0.5s opacity;
    transition-delay: 0.25s;
  }
}

.NewsArticlePage-text {
  a {
    font-weight: bold;
    text-decoration: underline;
    transition: 0.25s color;

    &:hover {
      color: #d73a06;
    }
  }

  img {
    margin: 1rem 0;
  }

  @include mobile {
    img:first-child {
      margin-top: 3.5rem;
    }
  }

  blockquote {
    font-weight: bold;
    margin: 1.5em 0;
  }

  .embedded-image {
    width: 100%;
  }

  font-size: 1.6rem;
  line-height: 1.65;
  margin: auto;
  width: $mobileContentWidth;
  @include desktop {
    font-size: 1.8rem;
    line-height: 1.65;
    margin: 5rem 0;
    width: 55.6rem;
    opacity: 0;
  }
}

.NewsArticlePage-image {

  margin: 3.5rem 0;

  @include desktop {
    margin: 0;
    position: fixed;
    width: 52rem;
    height: 39rem;
    margin-left: 63.2rem;
  }

  img {
    width: 100%;
    display: none;

    &:nth-child(1) {
      display: block;
    }

    @include desktop {
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0;
      transition: opacity 0.5s;
      display: block !important;
    }

    &.visible {
      opacity: 1;
    }
  }
}

.NewsArticlePage-image-bottom {
  position: absolute;
  bottom: 8rem;
}
