@import '../../lib/common';

.Policy {
  position: relative;

  width: $mobileContentWidth;
  padding-bottom: 4rem;
  margin: 12rem auto 0;
  font-size: 1.6rem;
  line-height: 1.65;
  @include desktop {
    width: $desktopContentWidth;
    padding-bottom: 8rem;
    margin: 18rem auto 0;
    font-size: 1.8rem;
    line-height: 1.65
  }

  h3 {
    margin-top: 4rem;
  }

  ul {
    padding-left: 3rem;
    margin-left: 0;
  }

  a {
    text-decoration: underline;
    font-weight: bold;
  }
}
