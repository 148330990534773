@import '../../lib/common';

.SlideImage {
  position: relative;
  margin: auto;
  display: block;
  width: 100%;

  max-width: $mobileContentWidth;
  @include desktop {
    max-width: $desktopContentWidth;
  }

  img {
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.333s ease-out transform, 0.333s ease-out opacity;
    opacity: 0;
  }
  img:nth-child(1) {
    position: static;
    transform: translateX(-12%);
  }
  img:nth-child(2) {
    transform: translateX(5%);
  }
  img:nth-child(3) {
    transform: translateX(-5%);
  }
}

.SlideImage-active {
  img {
    transform: unset !important;
    opacity: 1 !important;
  }
  img:nth-child(2) {
    transition-delay: 0.333s;
  }
  img:nth-child(3) {
    transition-delay: 0.666s;
  }
}
