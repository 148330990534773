@import '../../lib/common';

.WorkProjectPage {
  width: 100%;
}

// ----------------------------------------------------------------------------

.WorkPageProject-background {
  position: fixed;
  left: 0;
  top: 0;
  z-index: -1;
  width: 100vw;
  height: 100vh;
  opacity: 0;
  background-size: cover;
  background-position: center;
}

// ----------------------------------------------------------------------------

.WorkPageProject-background-swipe {
  opacity: 1;
  animation-duration: 0.75s;
  animation-name: swipeIn;
  transition: 1s background-color;
}

.WorkPageProject-background-fade {
  transition: 1s opacity;
}

@keyframes swipeIn {
  from {
    width: 0;
  }

  to {
    width: 100vw;
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-header {
  position: relative;

  width: $mobilePageWidth;
  height: 55rem;
  @include desktop {
    width: 100%;
    height: 100rem;
  }
}

.WorkProjectPage-headerImageContainer {
  position: relative;
  max-width: $desktopPageWidth;
  margin: auto;
  height: 100%;
}

.WorkProjectPage-headerImage {
  position: absolute;
  right: 0;
  transform: translateY(-50%);
  max-height: 100%;
  top: 50%;

  max-width: 80vw;
  @include desktop {
    max-width: unset;
  }
}

.WorkProjectPage-title {
  position: absolute;
  z-index: 2;

  transform: rotate(3deg);
  bottom: 10%;
  left: 1.8rem;
  @include desktop {
    display: flex;
    align-items: center;
    transform: unset;
    left: calc((100vw - #{$desktopContentWidth}) / 2 - 1rem);
    top: 0;
    min-height: 46rem;
    height: calc(100vh - 6rem);
  }
}

.WorkProjectPage-titleInner {
  opacity: 0;
  animation-delay: 0.5s;
  animation-duration: 0.5s;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(1rem);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.WorkProjectPage-headerScrollContainer {
  display: none;
  @include desktop {
    display: block;
    position: absolute;
    top: 0;
    left: calc((100vw - #{$desktopContentWidth}) / 2);
    height: 100vh;
    min-height: 52rem;
    max-height: 100%;
  }
}

.WorkProjectPage-headerScroll {
  position: absolute;
  left: -1rem;
  bottom: 3rem;
  width: 5rem;
  transition: 0.5s opacity;
}

.WorkProjectPage-headerScroll-out {
  opacity: 0;
}

// ----------------------------------------------------------------------------

.WorkProjectPage-prelude {
  color: #fff;
  margin: auto;
  display: flex;
  flex-direction: column;

  font-size: 1.6rem;
  padding: 0 0 2rem;
  width: $mobileContentWidth;
  @include desktop {
    font-size: 1.8rem;
    padding: 0;
    width: $desktopContentWidth;
  }
}

.WorkProjectPage-preludeText {
  line-height: 1.6;
  padding: 0 0 1rem 0;
  display: flex;
  justify-content: space-between;

  flex-direction: column;
  @include desktop {
    flex-direction: row;
  }
}

.WorkProjectPage-preludeTextColumn {
  @include desktop {
    width: 48%;
  }
}

.WorkProjectPage-preludeRuleTop {
  margin-bottom: 4rem;
  width: $mobileContentWidth;
  @include desktop {
    display: none;
  }
}

.WorkProjectPage-preludeRuleBottom {
  @include mobile {
    display: none;
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-block {
  width: 114.6rem;
  margin: auto;
}

// ----------------------------------------------------------------------------

.WorkProjectPage-blockText {
  .BlockText-main {
    color: #D73A06;
    line-height: 0.9;
    padding: 0 0.1em;
    letter-spacing: -0.1rem;
    font-size: 4.2rem;
    margin: 0;
    @include desktop {
      font-size: 7.8rem;
    }
  }
  .BlockText-offset {
    top: -0.2rem;
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-textSplit {
  margin: auto;

  width: $mobileContentWidth;
  @include desktop {
    width: $desktopContentWidth;
  }

  // clearfix
  &::after {
    content: "";
    clear: both;
    display: table;
  }

  p:last-child {
    margin-bottom: 0;
  }
}

.WorkProjectPage-textSplitLeft {
  line-height: 1.65;
  letter-spacing: -0.03rem;

  font-size: 1.6rem;
  @include desktop {
    font-size: 1.8rem;
    width: 38.9rem;
  }
}

.WorkProjectPage-textSplitRight {
  line-height: 1.65;
  letter-spacing: -0.03rem;

  font-size: 1.6rem;
  @include desktop {
    font-size: 1.8rem;
    width: 38.9rem;
    margin-right: 26.4rem;
    float: right;
  }
}

.WorkProjectPage-textSplitRight-larger {
  margin-top: 3.2rem;
  font-size: 2rem;
  @include desktop {
    font-size: 3.2rem;
    line-height: 1.4;
    width: 65.3rem;
    margin-top: -9.1rem;
    margin-right: 0;
  }
}

// ----------------------------------------------------------------------------

// ----------------------------------------------------------------------------

.WorkProjectPage-textWithImage {
  position: relative;
  margin: 4rem 0;
  @include desktop {
    height: 80vh;
    margin: 6rem 0;
  }
}

.WorkProjectPage-textWithImageContent {
  position: relative;
  height: 100%;
  width: $mobileContentWidth;
  margin: auto;
  @include desktop {
    display: flex;
    align-items: center;
    width: $desktopContentWidth;
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.WorkProjectPage-textWithImageText {
  line-height: 1.65;
  font-size: 1.6rem;
  margin-bottom: 3rem;
  z-index: 2;
  @include desktop {
    margin-bottom: 0;
    font-size: 2.1rem;
    width: 48.7rem;
  }
}

.WorkProjectPage-textWithImageImage {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
  max-width: $mobileContentWidth;

  @include desktop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-height: 80vh;
    max-width: $desktopPageWidth * 2/3;
  }
}

.WorkProjectPage-textWithImage-imageRight {
  @include desktop {
    .WorkProjectPage-textWithImageImage {
      left: 54.7rem;
    }
  }
}

.WorkProjectPage-textWithImage-imageLeft {
  @include desktop {
    .WorkProjectPage-textWithImageImage {
      right: 54.7rem;
    }
    .WorkProjectPage-textWithImageText {
      position: absolute;
      right: 0;
    }
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-statementWithImage {
  position: relative;
  margin: 4rem 0;
  @include desktop {
    height: 80vh;
    margin: 4rem 0;
  }
}

.WorkProjectPage-statementWithImageContent {
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  width: $mobileContentWidth;
  margin: auto;
  @include desktop {
    width: $desktopContentWidth;
    margin: 0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
}

.WorkProjectPage-statementWithImageStatement {
  z-index: 2;
  transform: rotate(-2.5deg);
}

.WorkProjectPage-statementWithImageImage {
  display: block;
  margin-top: 1rem;
  margin-left: auto;
  max-width: $mobileContentWidth;
  transform: translateX(-50%);

  @include desktop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-height: 80vh;
    max-width: $desktopPageWidth * 2/3;
  }
}

.WorkProjectPage-statementWithImage-imageRight {
  @include desktop {
    .WorkProjectPage-statementWithImageImage {
      left: 54.7rem;
    }
  }
}

.WorkProjectPage-statementWithImage-imageLeft {
  .WorkProjectPage-statementWithImageImage {
    transform: translateX(-100%);
    @include desktop {
      transform: translateY(-50%);
      right: 54.7rem;
    }
  }
  .WorkProjectPage-statementWithImageStatement {
    transform: translateX(50%);
    @include desktop {
      transform: unset;
      position: absolute;
      right: 2rem;
    }
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-slideImage {
  margin: 6rem auto;
}

// ----------------------------------------------------------------------------

.WorkProjectPage-imageBlock {
  position: relative;
  display: flex;
  min-height: 80vh;
  background-repeat: none;
  background-size: cover;
  background-position: center;
}

.WorkProjectPage-imageBlockContainer {
  margin: auto;
  overflow: hidden;

  width: $mobileContentWidth;
  @include desktop {
    width: $desktopContentWidth;
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-wipImagesText {
  line-height: 1.4;
  margin-bottom: 3rem;

  margin: 0 auto 3rem;
  font-size: 2rem;
  @include desktop {
    margin: 0 0 3rem;
    font-size: 3.2rem;
    letter-spacing: -0.03rem;
    width: 94.5rem;
  }
}

.WorkProjectPage-wipImageContainer {
  display: flex;
  justify-content: space-between;
  list-style: none;
  padding: 0;
  margin: 0;

  width: $mobileContentWidth;
  flex-direction: column;

  @include desktop {
    flex-direction: row;
    width: $desktopContentWidth;
  }
}

.WorkProjectPage-wipImage {
  width: 100%;
  margin-bottom: 2rem;

  @include desktop {
    width: 49%;
  }
}

.WorkProjectPage-wipImageInner {
  padding-top: 3 / 4 * 100%;
  background-size: cover;
  background-position: center;
}

// ----------------------------------------------------------------------------

.WorkProjectPage-mobileImages {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;

  .swiper-slide {
    width: 23.3rem;
  }
}

.WorkProjectPage-mobileImagesWrapper {
  list-style: none;
  padding: 0;
  margin: auto;
  white-space: nowrap;
  transition: 0.25s transform;
  display: flex;
  justify-content: space-between;
  width: $desktopContentWidth;
}

.WorkProjectPage-mobileImage {
  display: inline-block;
  width: 23.3rem;

  @include desktop {
    margin: 1rem;
    display: block;
    height: unset;
    width: 25%;
  }
}

.WorkProjectPage-mobileImage+.WorkProjectPage-mobileImage {
  margin-left: 2.6rem;

  @include desktop {
    margin: 1rem;
  }
}

.WorkProjectPage-mobileImageInner {
  padding-top: 2 / 1 * 100%;
  background-size: cover;
  background-position: center;
}

.WorkProjectPage-mobileImagesIndicator {
  width: $mobileContentWidth;
  margin-left: 2rem;
  padding: 0;
  list-style: none;
}

.WorkProjectPage-mobileImagesIndicatorPip {
  display: inline-block;
  width: 2.9rem;
  height: 2.9rem;
  background: url("./pip.svg") no-repeat center;
  margin-right: 1rem;
  vertical-align: middle;
}

.WorkProjectPage-mobileImagesIndicatorPip-active {
  background-image: url("./pip-active.svg")
}

// ----------------------------------------------------------------------------

.WorkProjectPage-desktopMockups {
  padding: 4rem 0;

  @include desktop {
    padding: 4rem 0;

    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
}

.WorkProjectPage-desktopMockupsContainer {
  margin: auto;

  width: $mobileContentWidth;
  @include desktop {
    width: $desktopContentWidth;
  }
}

.WorkProjectPage-desktopMockupsText {
  line-height: 1.4;
  margin-bottom: 3rem;

  margin: 0 auto 3rem;
  font-size: 2rem;
  @include desktop {
    margin: 0 0 3rem;
    font-size: 3.2rem;
    letter-spacing: -0.03rem;
    width: 94.5rem;
  }
}

.WorkProjectPage-desktopMockupsContent {
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
  white-space: nowrap;

  img {
    width: 100%;
  }

  li {
    width: $mobilePageWidth;
    margin: 0;
    margin-left: -2.1rem;
  }

  @include desktop {
    margin: auto;
    width: $desktopContentWidth;

    li {
      display: inline-block;
      width: $desktopContentWidth;
      vertical-align: middle;
      margin: 0;
    }

    li+li {
      margin-left: 2rem;
    }
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-project {
  @include desktop {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
}

.WorkProjectPage-projectContainer {
  @include desktop {
    position: relative;
    margin: auto;
    width: $desktopContentWidth;
  }
}

.WorkProjectPage-projectContent {
  margin: auto;
  width: $mobileContentWidth;
  @include mobile {
    min-height: 35vh;
    padding-bottom: 3rem;
  }

  @include desktop {
    margin: 0;
    width: 48rem;
    font-size: 2.1rem;
    line-height: 165%;
  }
}

.WorkProjectPage-projectTitle {
  font-family: "Barlow Condensed";
  font-style: normal;
  font-weight: bold;
  line-height: 90%;
  margin-bottom: 2rem;
  font-size: 5rem;

  @include desktop {
    font-size: 7.8rem;
  }
}

.WorkProjectPage-projectText {
  @include mobile {
    font-size: 1.6rem;
    line-height: 1.65;
  }
}

.WorkProjectPage-projectImage {
  display: block;
  max-width: $mobilePageWidth;
  margin: auto;
  margin-bottom: 2rem;

  @include desktop {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 88.6rem;
    max-height: 90vh;
  }
}

.WorkProjectPage-project-imageLeft {
  @include mobile {
    .WorkProjectPage-projectImage {
      transform: translateX(-25%);
    }
  }
  @include desktop {
    .WorkProjectPage-projectContent {
      margin-left: 69rem;
    }
    .WorkProjectPage-projectTitle {
      transform: rotate(1deg);
    }
    .WorkProjectPage-projectImage {
      right: 52rem;
    }
  }
}

.WorkProjectPage-project-imageRight {
  @include mobile {
    .WorkProjectPage-projectImage {
      transform: translateX(25%);
    }
  }
  @include desktop {
    .WorkProjectPage-projectTitle {
      transform: rotate(-1deg);
    }
    .WorkProjectPage-projectImage {
      left: 52rem;
    }
  }
}

// ----------------------------------------------------------------------------

.WorkProjectPage-serviceList {
  list-style: none;
  margin: 0;

  padding: 6rem 0 8rem;
  @include desktop {
    padding: 10rem 0;
  }
}

.WorkProjectPage-serviceHeader {
  font-size: 1.6rem;
  text-align: center;
  margin-bottom: 3.5rem;
  letter-spacing: -0.3px;
}

.WorkProjectPage-serviceItem {
  font-family: "Barlow Condensed", sans;
  text-align: center;
  line-height: 1.75;

  font-size: 3rem;
  @include desktop {
    font-size: 5rem;
  }
}
