@import '../../lib/common';

.Header {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;

  // display: none;
  @include desktop {
    display: block;
  }
}

.Menu {
  position: fixed;
  left: 0;
  top: 0;
  width: 0;
  height: 100vh;
  background: #090150;
  opacity: 1;
  overflow: hidden;
  animation-duration: 0.35s;
  z-index: 101;

  img {
    filter: invert(1);
  }
}

.Menu--show {
  width: 100vw;
  animation-name: menuSwipeIn;
}

.Menu--hide {
  width: 0;
  animation-name: menuSwipeOut;
}

.Menu-content {
  position: relative;
  width: 100vw;
}

.Header-content-invert {
  filter: invert(1);
}

@keyframes menuSwipeIn {
  from { width: 0; }
  to { width: 100vw; }
}

@keyframes menuSwipeOut {
  from { width: 100vw; }
  to { width: 0; }
}

.Header-logo {
  position: absolute;
  transform: translateX(-50%);
  left: 50%;
  top: 1rem;
  @include desktop {
    top: 2rem;
  }

  img {
    width: 9rem;
  }
}

.Header-email {
  position: absolute;
  font-weight: 700;
  text-decoration: none;
  color: #000;

  top: 2.7rem;
  right: 1.9rem;
  @include desktop {
    top: 2rem;
    right: 3rem;
  }

  img {
    @include desktop {
      display: none;
    }
  }
}

.Header-emailText {
  display: none;
  @include desktop {
    display: block;
  }
}

.Header-menuButton {
  position: absolute;
  top: 1.8rem;
  left: 1.8rem;

  @include desktop {
    display: none;
  }
}

.Header-closeButton {
  position: absolute;
  top: 3rem;
  left: 2.1rem;
}

.Header-navLogo {
  position: relative;
  top: 1rem;
}

.Nav {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;

  font-family: 'Barlow Condensed', sans-serif;
  font-size: 3.5rem;
  text-transform: uppercase;
  left: 2.1rem;
  top: 10rem;
  line-height: 1.9;

  left: 0;
  right: 3.25rem;
  text-align: center;

  @include desktop {
    font-family: 'Libre Baskerville', serif;
    font-size: 1.8rem;
    text-transform: unset;
    left: 3rem;
    top: 2rem;
    line-height: 4.2rem;
    right: unset;
    text-align: left;
  }
}

.NavLink {
  display: inline-block;
  font-weight: 700;
  text-decoration: none;
  color: #fff;

  span {
    display: inline-block;
    transition: 0.25s transform;
  }

  @include desktop {
    color: #000;

    &:hover span {
      transform: translateX(1.06em);
    }
  }

  @include mobile {
    &::before {
      content: "•";
      margin-right: 0.6em;
      opacity: 0;
    }
  }
}

.NavLink--active {
  &::before {
    content: "•";
    margin-right: 0.6em;
    opacity: 1;
  }

  &:hover span {
    transition: none;
    transform: none;
  }
}
