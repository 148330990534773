@import '../../lib/common';

.BlockText {
  white-space: nowrap;
}

.BlockText-main {
  font-family: "Barlow Condensed", sans;
  color: #000;
  font-weight: 800;
  letter-spacing: -0.2rem;
  line-height: 0.7;
  display: inline-block;
  padding: 0.1rem 0.4rem 0.6rem 0.2rem;
  background: #fff;
  margin: 0.15rem 0;
  font-size: 6rem;

  @include desktop {
    line-height: 0.75;
    padding: 0 0.6rem 0.6rem 0.4rem;
    margin: 0.25rem 0;
    font-size: 10rem;
  }
}

.BlockText-offset {
  position: relative;
}
